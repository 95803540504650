import React, { useState } from "react"
import { graphql } from "gatsby"
import get from "lodash/get"
import Layout from "../components/layout"
import SEO from "../components/seo"

const LIMIT_PER_PAGE = 10

const ReviewsPage = ({ data }) => {
  const [page, setPage] = useState(0);
  const reviews = get(data, "allContentfulReview.nodes", [])
  const amountOfPages = Math.ceil(reviews.length / LIMIT_PER_PAGE)
  const pagedReviews = amountOfPages > 1 ? reviews.slice(page * LIMIT_PER_PAGE, (page + 1) * LIMIT_PER_PAGE) : reviews;

  return (
    <>
      <SEO title="Recensies" keywords="recenties, reviews" />
      <Layout full>
        <section className="container p-6">
          <h1>Recensies</h1>

          {amountOfPages > 1 ? (
            <div className="flex flex-col mb-8 space-y-1">
              <span className="text-xs font-medium uppercase">Pagina's</span>

              <div className="flex items-center space-x-2">
                {[...Array(amountOfPages).keys()].map((p) => {
                  const pageActive = p === page;
                  const cls = pageActive ? 'bg-primary-700 text-white' : 'bg-primary-300 text-white'
                  return <button key={p} className={`${cls} font-bold px-2 py-1`} onClick={() => setPage(p)}>{p + 1}</button>
                })}
              </div>
            </div>
          ) : null}

          <div className="box-border mx-auto md:masonry-2-col lg:masonry-3-col before:box-inherit after:box-inherit">
            {pagedReviews && pagedReviews.length > 0 ?
              pagedReviews.map(({ name, date, feedback }, index) => (
                <div key={`${name}-${index}`} className="break-inside">
                  <div className="flex flex-col">
                    <span className="text-xs font-medium uppercase text-primary-700">{date}</span>
                    <strong className="text-xl">{name}</strong>
                  </div>

                  <div className="mt-2 html" dangerouslySetInnerHTML={{ __html: get(feedback, 'childMarkdownRemark.html') }} />
                </div>
              )) : null}
          </div>
        </section>
      </Layout>
    </>
  )
}

export const pageQuery = graphql`
query ReviewPageQuery {
  allContentfulReview(sort: {fields: date, order: DESC}) {
    nodes {
      name
      date(formatString: "DD MMMM YYYY", locale: "nl-BE")
      feedback {
        childMarkdownRemark {
          html
        }
      }
    }
  }
}
`

export default ReviewsPage
